
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent } from '@ionic/vue';
import { chevronDownCircleOutline } from 'ionicons/icons';
import axios from 'axios';
import CopyToClipboard from './CopyToClipboard.vue';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from './header_toolbar.vue';
export default defineComponent({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, CopyToClipboard, headertoolbar, IonRefresher, IonRefresherContent },
  data() {
    return {
      audios: [{
        id: 1,
        name: "Lista de Éxitos",
        imagePath: "lista-de-exitos.png"
      }],
      linksReq: [],
      conectados: [],
      conectadosTotal: -1,
      clientlink: "",
      cargando: '0<span class="out_loader_mini"><div class="loader_mini"></div></span>',
      ddata: [],
      isLoading: false,
      clientData: {},

    }
  },
  methods: {
    async loadClient() {
      console.log("LINKS: loadClient i--------------------");
      this.linksReq = [];
      this.linksReq = this.ddata.radios;
      this.currentLink = this.ddata.link ? this.ddata.link : "";
      this.clientlink = this.ddata.link;
      this.linksReq = this.ddata.radios;

      // console.log(this.ddata);
      if (this.ddata) {
        this.isLoading = true;
        axios.post("https://panel.vybroo.net/dashboard")
          .then(response => {
            if (response.data) {
              console.log('conectados: ===');
              console.log(response);
              this.conectados = response.data.data.todos;
              console.log('---conectados');
              console.log(this.conectados);
              console.log('---conectados');
              var conectadosTotalAUX = 0;
              for (var valor of this.linksReq) {
                //console.log("Valor: " + valor.stream);
                //console.log(this.conectados[valor.stream]);
                if (this.conectados[valor.stream]) {
                  conectadosTotalAUX += Number(this.conectados[valor.stream]);
                } else {
                  this.conectados[valor.stream] = "0";
                }
              }
              this.conectadosTotal = conectadosTotalAUX;
              console.log(this.conectados);
              console.log('conectados: ===');
            } else {
              console.log("AXIOS---no_response");
            }
            this.isLoading = false;
          })
          .catch(error => {
            console.error("conectados: There was an error!", error);
            this.isLoading = false;
          });
      }
      console.log("AudiosDex: loadClient f--------------------");
    },
    async confirmarCambioLink(nuevoLink) {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "Cambiar el link terminará la sesión actual y cargará una nueva. ¿Deseas continuar?",
          buttons: [
            {
              text: 'Si, cambiar de link',
              handler: () => {
                console.log('Cambiar sesion pulsado, nuevo link: ' + nuevoLink);
                this.cambioDeLink(nuevoLink);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();
    },
    async cambioDeLink(nuevoLink) {
      const clientId = { id: nuevoLink };
      await Storage.remove({ key: 'clientData' }).then
      axios.post("https://vybroo.net/client", clientId)
        .then(response => {
          if (response.data.error == true || response.data.disabled == true) {
            if (response.data.error == true) console.log("El canal no existe.");
            if (response.data.disabled == true) console.log("El canal existe pero está deshabilitado.");
            this.presentAlertConfirm("Canal no encontrado", "No se ha podido encontrar el canal " + this.mLoginInput.toLowerCase() + ".")
          } else {
            console.log("Canal encontrado");
            this.setClientData("clientData", response.data);
            this.getClientData("clientData");
            this.emitter.emit("g-data", response.data);
            this.loadClient();
            this.$router.go("/tabs/tabReproductor");
            // this.$router.replace("/tabs/tabReproductor");

          }
          // console.log(response.data);
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
    },
    setClientData(keyName, dataValue) {
      Storage.set({
        key: keyName,
        value: JSON.stringify(dataValue),
      })
        .then
      console.log("Asignado a storage exitosamente - " + JSON.stringify(dataValue));
    },
    async getClientData(keyName) {
      const ret = await Storage.get({ key: keyName });
      this.clientData = ret.value;
      // console.log("Recuperado de storage - " + this.clientData);
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    swipeToRefresh(event) {
      console.log('Begin async operation');
      // setTimeout(() => {
      this.loadClient().then(() => {
        console.log('Async operation has ended');
        event.target.complete();
      });
      // }, 2000);
      return chevronDownCircleOutline;
    }
  },
  mounted: function () {
    console.log('mounted LINKS +++++');
    this.emitter.on("g-data", ddata => {
      this.ddata = ddata;
      console.log('mounted LINKS **');
      this.loadClient();
    });
    console.log('mounted LINKS -----');
    window.setInterval(() => {
      console.log('Actualizando conectados...');
      this.loadClient();
    }, 60000);
    this.getFullClientData();
  },
  computed: {
    getImgUrl(pic) {
      // return require('~@/assets/audios/'+pic);
      return (pic);
    }
  }
})
